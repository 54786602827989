@font-face {
    font-family: 'icomoon';
    src:  url('../.fonts/icomoon/icomoon.eot?q2wobt');
    src:  url('../fonts/icomoon/icomoon.eot?q2wobt#iefix') format('embedded-opentype'),
      url('../fonts/icomoon/icomoon.ttf?q2wobt') format('truetype'),
      url('../fonts/icomoon/icomoon.woff?q2wobt') format('woff'),
      url('../fonts/icomoon/icomoon.svg?q2wobt#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-paperclip-solid:before {
    content: "\e90a";
  }  
  .icon-ext:before {
    content: "\e904";
  }
  .icon-left:before {
    content: "\e901";
  }
  .icon-right:before {
    content: "\e902";
  }
  .icon-up:before {
    content: "\e900";
  }
  .icon-down:before {
    content: "\e903";
  }
  .icon-line:before {
    content: "\e905";
  }
  .icon-facebook:before {
    content: "\e906";
  }
  .icon-twitter:before {
    content: "\e907";
  }
  .icon-hatena:before {
    content: "\e908";
  }
  .icon-instagram:before {
    content: "\e909";
  }
  