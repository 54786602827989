//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Sawarabi+Mincho:wght@100;300;400;500;600;700&display=swap');
//$font-family-base: "游ゴシック体", "Yu Gothic", YuGothic,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Noto Sans Japanese','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
$font-family-base: "Sawarabi Mincho", sans-serif;

@import "variables";
@import "bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------


//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/wordpress";
@import "components/icomoon";
@import "components/hamburgers";
